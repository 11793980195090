import React, { useState, useEffect, useRef } from "react"
import { useMediaQuery } from "react-responsive"
import Clients from "../../components/clients/clients"
import SliderActioner from "../../components/sliderActioner/sliderActioner"
import * as styles from "./case_study_archive.module.scss"
import {
  animationSlideUp,
  animationSlideRight,
  animationSlideLeft,
  animationFade,
  animationZoomIn,
} from "../../utils/scroll-animation"
import NextArrow from "../../../assets/next.svg"
import BackArrow from "../../../assets/back.svg"

export default function CaseStudyArchive(props) {
  const { cases } = props
  const page_size = 9
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 })
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 767 })
  const customSlider = useRef(null)
  const [slidePages, setSlidePages] = useState([])

  var sliderSetting = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    adaptiveHeight: true,
    draggable: false,
    slidesToShow: 1,
    appendDots: dots => (
      <div>
        <SlickArrowLeft />
        <ul style={{ margin: "0px" }}> {dots} </ul>
        <SlickArrowRight />
      </div>
    ),
  }

  useEffect(() => {
    if (cases?.edges.length > 0) {
      setSlidePages(createPaginatedSlides(cases?.edges, page_size))
    }
  }, [])

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
      onClick={() => customSlider.current.slickPrev()}
    >
      <BackArrow />
    </button>
  )

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
      onClick={() => customSlider.current.slickNext()}
    >
      <NextArrow />
    </button>
  )

  const createPaginatedSlides = (array, page_size) => {
    return array.reduce((acc, val, i) => {
      let idx = Math.floor(i / page_size)
      let page = acc[idx] || (acc[idx] = [])
      page.push(val)

      return acc
    }, [])
  }

  return (
    <section className={styles.caseStudyArchive} id="case_studies">
      <div className="default-container">
        <div className={styles.caseStudyArchiveContainer}>
          <div className={styles.contentWrapper}>
            <div className={styles.caseStudyArchiveSlider}>
              <SliderActioner
                sliderClass="inner-pages-slider"
                sliderSetting={sliderSetting}
                reference={slider => (customSlider.current = slider)}
              >
                {slidePages.map((caseItem, index) => (
                  <div
                    key={index}
                    className={styles.caseStudyArchiveSliderItem}
                  >
                    {caseItem.map((item, index) => (
                      <Clients
                        key={index}
                        client={item.node}
                        cardWidth="100%"
                      />
                    ))}
                  </div>
                ))}
              </SliderActioner>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
