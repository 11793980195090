import { graphql, useStaticQuery } from "gatsby"

export default function CaseStudyData() {
  return useStaticQuery(graphql`
    query queryCaseStudy {
      seo: sanitySeo(slug: { current: { eq: "case-study" } }) {
        meta_title
        meta_description
        keywords
        meta_image {
          asset {
            url
          }
        }
        alt_tag
      }
      hero: sanityHero(slug: { current: { eq: "case-studies" } }) {
        title
        hints {
          parts
        }
        svgUpload
        isHintsAnimation
        backgroundColor {
          hex
        }
      }
      case_study: allSanityCaseStudy {
        edges {
          node {
            case_card {
              id
              logo {
                asset {
                  url
                }
              }
              name
              description
              technologies {
                name
                logo {
                  asset {
                    url
                  }
                }
              }
            }
            slug {
              current
            }
          }
        }
      }
    }
  `)
}
