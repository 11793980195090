import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Layout from "../components/Layout"
import Hero from "../sections/hero/hero"
import CaseStudyArchive from "../sections/case_study_archive/case_study_archive"
import * as hero_styles from "../sections/hero/hero_index.module.scss"
import { getSeo } from "../utils/seo"

import CaseStudyData from "../utils/case-study-queries"

export default function CaseStudy() {
  const { hero, case_study } = CaseStudyData()
  return (
    <Layout navBG={hero?.backgroundColor ? hero.backgroundColor.hex : ""}>
      {/* <Seo title={"Case Study"} /> */}
      <Hero hero={hero} styles={hero_styles} nextSection="case_studies" />
      <CaseStudyArchive cases={case_study} />
    </Layout>
  )
}
export const Head = () => {
  const { seo } = CaseStudyData()
  return getSeo(seo)
}
