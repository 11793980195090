// extracted by mini-css-extract-plugin
export var appSlider = "case_study_archive-module--app-slider--2S_G5";
export var caseStudy = "case_study_archive-module--case-study--3e9O9";
export var caseStudyArchive = "case_study_archive-module--case-study-archive--2vpIv";
export var caseStudyArchiveContainer = "case_study_archive-module--case-study-archive-container--g2zKI";
export var caseStudyArchiveSlider = "case_study_archive-module--case-study-archive-slider--BDtkZ";
export var caseStudyArchiveSliderItem = "case_study_archive-module--case-study-archive-slider-item--1TnlX";
export var caseStudyContainer = "case_study_archive-module--case-study-container--3JqjL";
export var contentWrapper = "case_study_archive-module--content-wrapper--2Fls_";
export var defaultContainer = "case_study_archive-module--default-container--1ea8X";
export var featureSlider = "case_study_archive-module--feature-slider--nA5A1";
export var innerPagesSlider = "case_study_archive-module--inner-pages-slider--3-aNM";
export var leftAppSlide = "case_study_archive-module--left-app-slide--2J5zY";
export var logoSlider = "case_study_archive-module--logo-slider--1mfTy";
export var phoneAppSelect = "case_study_archive-module--phone-app-select--3fNMF";
export var phoneCaseSelect = "case_study_archive-module--phone-case-select--3qS-L";
export var rightAppSlide = "case_study_archive-module--right-app-slide--1keOo";
export var slickActive = "case_study_archive-module--slick-active--3iWc3";
export var slickArrow = "case_study_archive-module--slick-arrow--zEeWf";
export var slickCenter = "case_study_archive-module--slick-center--8GUYj";
export var slickDots = "case_study_archive-module--slick-dots--271bn";
export var slickNext = "case_study_archive-module--slick-next--2DijS";
export var slickPrev = "case_study_archive-module--slick-prev--zKRgr";
export var slickSlide = "case_study_archive-module--slick-slide--2GA1w";
export var slickSlider = "case_study_archive-module--slick-slider--1pFb_";
export var slickTrack = "case_study_archive-module--slick-track--3oSrM";
export var solvingSlider = "case_study_archive-module--solving-slider--b4O0D";
export var subtitle = "case_study_archive-module--subtitle--1Ts0C";
export var title = "case_study_archive-module--title--19q0z";
export var titleWrapper = "case_study_archive-module--title-wrapper--1Yv2z";